<template>
  <div class="container">
    <div class="testimonial-titles">
      <div class="low-title">
        <span>{{ $t('home_page.testimonials.title') }}</span>
      </div>
      <div class="title">
        <span>{{ $t('home_page.testimonials.say_about') }}</span>
      </div>
    </div>
    <div class="testimonial-description">
      <span>{{ $t('home_page.testimonials.description') }}.</span>
    </div>
    <div class="testimonial-list">
      <div
        class="testimonial-card item"
        v-for="item in testimonials.slice(0, 3)"
        :key="item._id"
      >
        <div class="item-box">
          <div class="item-header">
            <div class="icon">
              <img src="./img/quote.svg" alt="quote" />
            </div>
            <div class="date">
              <span>10/28/12</span>
            </div>
          </div>
          <div class="item-body">
            <div class="text">
              <span>{{ item.text | intl }}</span>
            </div>
          </div>
          <div class="item-footer">
            <div class="user">
              <div class="user-photo">
                <img src="./img/Brooklyn.svg" alt="Brooklyn" />
              </div>
              <div class="user-data">
                <span class="name">Brooklyn Simmons</span>
                <span class="job">Nursing Assistant</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="read-more-button-block">
      <router-link :to="{ name: 'review' }" class="read-more-btn">{{
        $t('home_page.testimonials.more')
      }}</router-link>
    </div>
  </div>
</template>
<script>
import http from '../../../http'

export default {
  name: 'testimonials',
  data: () => {
    return {
      testimonials: null,
    }
  },
  methods: {
    getTestimonials() {
      http.api
        .post('contact-us/pagination', {})
        .then(({ data }) => {
          this.testimonials = data.docs
        })
        .catch(err => {})
    },
  },
  mounted() {
    this.getTestimonials()
  },
}
</script>
