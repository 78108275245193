<template>
  <div class="app-page home-page">
    <section class="smart-forex container-fluid">
      <div class="container">
        <div class="row">
          <div class="smart-box letf-side col-md-6 col-sm-6">
            <div class="smart-box__title">
              <span class="smart-color">{{ $t('home_page.title_1') }}</span>
              <span class="forex-color">{{ $t('home_page.title_2') }}</span>
            </div>
            <div class="smart-box__description">
              <div class="paragraph_1">
                <p>
                  {{ $t('home_page.description.paragraph_1') }}
                </p>
              </div>
              <div class="paragraph_2">
                <p>{{ $t('home_page.description.paragraph_2') }}</p>
              </div>
            </div>
            <div class="smart-box__buttons">
              <router-link to="user/account">
                <button class="try">{{ $t('home_page.try_free') }}</button>
              </router-link>

              <span class="watch">{{ $t('home_page.more') }}</span>
            </div>
          </div>
          <div class="smart-box right-side col-md-6 col-sm-6">
            <div class="smart-box__nav-menu">
              <nav class="nav-menu">
                <ul class="menu-item">
                  <li>{{ $t('home_page.menu') }}</li>
                  <li>{{ $t('home_page.features') }}</li>
                  <li>{{ $t('home_page.account') }}</li>
                  <li>{{ $t('home_page.contact') }}</li>
                  <li>{{ $t('home_page.about') }}</li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="why-us container-fluid">
      <div class="container">
        <div class="row">
          <div class="why-us__title">
            <div class="benefit">
              <span>{{ $t('home_page.benefit') }}</span>
            </div>
            <div class="choose-us">
              <span>{{ $t('home_page.why') }}</span>
            </div>
          </div>
          <div class="why-us__information">
            <div class="col-md-3 col-sm-3 item first">
              <div class="image">
                <img src="~@/assets/img/icon1.svg" alt="image" />
              </div>
              <div class="title">
                <span>{{ $t('home_page.licensed') }}</span>
              </div>
              <div class="description">
                <span>{{ $t('home_page.licensed_description') }}</span>
              </div>
            </div>
            <div class="col-md-3 col-sm-3 item">
              <div class="image">
                <img src="~@/assets/img/icon2.svg" alt="image" />
              </div>
              <div class="title">
                <span>{{ $t('home_page.daily') }}</span>
              </div>
              <div class="description">
                <span>{{ $t('home_page.daily_description') }}</span>
              </div>
            </div>
            <div class="col-md-3 col-sm-3 item">
              <div class="image">
                <img src="~@/assets/img/icon3.svg" alt="image" />
              </div>
              <div class="title">
                <span>{{ $t('home_page.instant') }}</span>
              </div>
              <div class="description">
                <span>{{ $t('home_page.instant_description') }}</span>
              </div>
            </div>
            <div class="col-md-3 col-sm-3 item last">
              <div class="image">
                <img src="~@/assets/img/icon4.svg" alt="image" />
              </div>
              <div class="title">
                <span>24/5 {{ $t('home_page.suport') }}</span>
              </div>
              <div class="description">
                <span>{{ $t('home_page.suport_description') }}</span>
              </div>
            </div>
          </div>
          <div class="why-us__video-block">
            <div class="image">
              <img src="~@assets/img/play_circle_filled.png" alt="image" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="about-us container-fluid">
      <div class="container">
        <div class="row">
          <div class="col-md-2 col-sm-2"></div>
          <div class="col-md-10 col-sm-10 about-item">
            <div class="about-box">
              <div class="about-us__title">
                <div class="about">
                  <span>{{ $t('home_page.about_us') }}</span>
                </div>
                <div class="welcome">
                  <span>{{ $t('home_page.welcom') }}</span>
                </div>
              </div>
              <div class="about-us__description">
                <span>{{ $t('home_page.welcom_description') }}</span>
              </div>
              <div class="about-us__button">
                <router-link to="user/account">
                  <button class="try">{{ $t('home_page.try_free') }}</button>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="trade container-fluid">
      <div class="container">
        <div class="row">
          <div class="trade__title title-item">
            <div class="opportunities">
              <span>{{ $t('home_page.opportunity') }}</span>
            </div>
            <div class="trade-smart">
              <span>{{ $t('home_page.oportunity_title') }}</span>
            </div>
          </div>
          <div class="col-md-6 col-sm-6 trade-item">
            <div class="description">
              <span>{{ $t('home_page.oportunity_descriprion') }}</span>
            </div>
            <div class="buttons">
              <button class="read-more">{{ $t('home_page.read_more') }}</button>
              <button class="download">{{ $t('home_page.download') }}</button>
            </div>
          </div>
          <div class="col-md-6 col-sm-6 trade-item">
            <!-- <div class="image">
              <img src="~@assets/img/graph.png" alt="image">
            </div> -->
          </div>
        </div>
      </div>
    </section>
    <section class="testimonials container-fluid">
      <Testimonial />
    </section>
    <section class="account container-fluid">
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-sm-12 ac_item">
            <div class="account-item">
              <div class="account__title">
                <div class="open">
                  <span>{{ $t('home_page.open_account') }}</span>
                </div>
                <div class="one">
                  <span>{{ $t('home_page.open_account_title') }}</span>
                </div>
              </div>
              <div class="account__description">
                <span>{{ $t('home_page.open_account_descriprion') }}</span>
              </div>
              <div class="account__list">
                <div class="maximum_leverage">
                  <div class="ml__title">
                    <div class="image">
                      <img src="~@assets/img/checked.png" alt="image" />
                    </div>
                    <div class="text">
                      <span>{{ $t('home_page.laverage') }}</span>
                    </div>
                  </div>
                  <div class="bold-text">
                    <div class="text">
                      <span>1:400</span>
                    </div>
                  </div>
                </div>
                <div class="maximum_leverage">
                  <div class="ml__title">
                    <div class="image">
                      <img src="~@assets/img/checked.png" alt="image" />
                    </div>
                    <div class="text">
                      <span>{{ $t('home_page.instruments') }}</span>
                    </div>
                  </div>
                  <div class="bold-text">
                    <div class="text">
                      <span>130+</span>
                    </div>
                  </div>
                </div>
                <div class="maximum_leverage">
                  <div class="ml__title">
                    <div class="image">
                      <img src="~@assets/img/checked.png" alt="image" />
                    </div>
                    <div class="text">
                      <span>{{ $t('home_page.spreds') }}</span>
                    </div>
                  </div>
                  <div class="bold-text">
                    <div class="text">
                      <span>{{ $t('home_page.competitive') }}</span>
                    </div>
                  </div>
                </div>
                <div class="maximum_leverage">
                  <div class="ml__title">
                    <div class="image">
                      <img src="~@assets/img/checked.png" alt="image" />
                    </div>
                    <div class="text">
                      <span>{{ $t('home_page.minimum_size') }}</span>
                    </div>
                  </div>
                  <div class="bold-text">
                    <div class="text">
                      <span>0.01</span>
                    </div>
                  </div>
                </div>
                <div class="maximum_leverage">
                  <div class="ml__title">
                    <div class="image">
                      <img src="~@assets/img/checked.png" alt="image" />
                    </div>
                    <div class="text maximum-open">
                      <span>{{ $t('home_page.maximum_orders') }}</span>
                    </div>
                  </div>
                  <div class="bold-text">
                    <div class="text">
                      <span>{{ $t('home_page.unlimited') }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="buttons">
                <router-link to="user/account">
                  <button class="start px-2">
                    {{ $t('home_page.start') }}
                  </button>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="cards container-fluid">
      <div class="container">
        <div class="row">
          <div class="col-md-4 col-sm-4 cards_accept">
            <div class="cards__title">
              <span>{{ $t('home_page.accept') }}</span>
            </div>
          </div>
          <div class="col-md-8 col-sm-8 cards__list">
            <div class="skrill m-2">
              <img src="~@assets/img/skrill.png" alt="skrill" />
            </div>
            <div class="visa m-2">
              <img src="~@assets/img/visa.png" alt="visa" />
            </div>
            <div class="mc m-2">
              <img src="~@assets/img/mc.png" alt="master_card" />
            </div>
            <div class="neteller m-2">
              <img src="~@assets/img/neteller.png" alt="neteller" />
            </div>
            <div class="wire m-2">
              <img src="~@assets/img/wire.png" alt="wire" />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Testimonial from './Testimonial.vue'
export default {
  components: {
    Testimonial,
  },
}
</script>

<style lang="scss" src="./styles.scss"></style>
